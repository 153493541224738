import React, { useMemo } from "react"
import { graphql, PageProps } from "gatsby"

import MasonryGrid from "../../components/MasonryGrid/MasonryGrid"
import Seo from "../../components/seo"

import loadable from "@loadable/component"
import { enricheLotWithAuction, getAuctionsIds } from "../../utils/auction"
import SaleHero from "../../components/SaleHero/SaleHero"

import Container from "../../components/Container/Container"
import Page from "../../components/Page/Page"

const LoadableSaleMonitor = loadable(
  () => import("../../components/GalleryMonitor/GalleryMonitor")
)

const Sale = ({ data }: PageProps<any>) => {
  const auctions = useMemo(() => {
    return getAuctionsIds(data?.contentfulSale?.lots)
  }, [data])

  return (
    <Page>
      <Seo
        title={
          "Artists Who Code | Generative NFTs by Women and Nonbinary Artists"
        }
        image={`https:${data?.contentfulSale?.mainMedia?.file?.url}`}
        description={`In celebration of Women's History Month, Artsy presents Artists Who Code, an auction of Generative NFTs by women and nonbinary artists curated by artist and writer Mieke Marple and Vellum LA director Sinziana Velicescu. NFTs are considered a male-dominated space, and Artists Who Code foregrounds the central role and major impact women and nonbinary creators have in digital art and technology. This auction features an exclusive selection of artworks by Marjan Moghaddam, Sofia Crespo, LIA, Alida Sun, Helena Sarin, Ellie Pritts, Cibelle Cavalli Bastos, and more, that explore generative techniques in art making. As part of Artsy's mission to empower women and nonbinary creators, 5% of the total sale from the auction will be donated to the non-profit Girls Who Code. 

        Bidding for this auction will be conducted in ETH and will start to close on Tuesday, April 5th, 2022 at 12:00pm EDT.`}
      />

      <SaleHero data={data?.contentfulSale} />

      <Container>
        <LoadableSaleMonitor
          auctions={auctions}
          render={({ result }) => {
            const enrichedData = enricheLotWithAuction(
              data?.contentfulSale?.lots || [],
              result?.auctions
            )

            return <MasonryGrid col={3} data={enrichedData} showSort={true} />
          }}
        />
      </Container>
    </Page>
  )
}

export default Sale

export const saleQuery = graphql`
  query ($saleId: String!) {
    contentfulSale(contentful_id: { eq: $saleId }) {
      createdAt
      endDate
      name
      slug

      mainMedia {
        file {
          contentType
          url
        }
        gatsbyImageData
        title
      }
      description {
        description
      }
      heroHeadline {
        heroHeadline
      }
      startDate
      endDate
      lots {
        auctionId
        lotId
        ended
        additionalMedia {
          gatsbyImageData(placeholder: BLURRED)
          file {
            contentType
            url
          }
        }
        asset {
          ... on ContentfulNftAsset {
            id
            slug
            title
            limitedEdition
            artist {
              name
              slug
              profilePicture {
                gatsbyImageData
              }
              description {
                description
              }
            }
            mainMedia {
              file {
                contentType
                url
              }
              gatsbyImageData(width: 620)
              videoH264: videoProfile(profile: "preview", maxWidth: 620) {
                path
              }
            }
            animationPreviewMedia {
              file {
                contentType
                url
              }
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
