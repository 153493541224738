import React from "react"
import ReactMarkdown from "react-markdown"

import * as styles from "./SaleHero.module.scss"
import Container from "../Container/Container"

const Hero = ({ data }: any) => {
  return (
    <Container>
      <div className={styles.hero}>
        <div className={styles.heroInner}>
          <div>
            <h1>{data.name}</h1>
            <div className={styles.heroSubHeadline}>
              <ReactMarkdown className="lineBreak">
                {data?.heroHeadline?.heroHeadline}
              </ReactMarkdown>
            </div>
          </div>
          <img
            className={styles.heroImg}
            src={data?.mainMedia?.file?.url}
            alt=""
          />
        </div>

        <div className={styles.heroDescription}>
          <div className={styles.heroDescriptionInner}>
            <ReactMarkdown>{data.description.description}</ReactMarkdown>
          </div>

          <div className={styles.heroDescriptionQuestion}>
            <div className={styles.heroDescriptionQuestionInner}>
              <span>Questions?</span>
              <a
                href="https://support.artsy.net/hc/en-us/articles/4420110097815-Placing-a-Bid"
                target="_blank"
                rel="noreferrer"
              >
                How to Bid on Artsy
              </a>
            </div>
            <div className={styles.heroDescriptionQuestionInner}>
              <span>Contact us</span>
              <a href="mailto: specialist@artsy.net">specialist@artsy.net</a>
              <p>+1-646-381-3901</p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Hero
